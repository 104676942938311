import { useQuery } from '@tanstack/react-query';
import { getIngredientList, getShopList } from 'apis/shop';

export const useGetShopList = () => {
  return useQuery({
    queryKey: ['shopList'],
    queryFn: () => {
      return getShopList();
    },
  });
};

export const useGetIngredient = (shopId: number) => {
  return useQuery({
    queryKey: ['ingredient', shopId],
    queryFn: () => {
      return getIngredientList(shopId);
    },
  });
};
