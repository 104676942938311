import axiosInstance from 'apis';
import { Place } from 'features/payment/types';
import { ApiListResponse, PagingParams, RecentOrderItem } from './types';

interface Order {
  ingredientIds: string[];
  packageType: Place;
  temperatureType: 'ICE' | 'HOT';
  sizeType: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE';
}

interface PostOrderResponse {
  orderId: string;
  type: Place;
  priceSum: number;
  ingredients: {
    id: string;
    name: string;
    description: string;
    price: number;
    thumbnail: string;
  };
  shopId: number;
}

interface PostOrderRequestBody {
  orders: Order;
}

export const postOrder = async (shopId: string, body: PostOrderRequestBody) => {
  try {
    const res = await axiosInstance.post<PostOrderResponse>(`/shop/${shopId}/order`, body.orders);

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getRecentOrderList = async ({ order = 'ASC', page, take = 10 }: PagingParams): Promise<ApiListResponse<RecentOrderItem>> => {
  const response = await axiosInstance.get('/shop/orders', { params: { order, page, take } });
  return response.data;
};
