import CartContents from 'features/cart/CartContents';
import React, { useEffect } from 'react';
import useOrderStore from 'store/useOrderStore';
import useLayout from 'features/layout/useLayout';
import { BottomButton } from 'features/layout/LayoutContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import EmptyCartContents from 'features/cart/EmptyCartContents';

const CartPage = () => {
  const { cart } = useOrderStore();
  const isExistCartList = cart && cart.length !== 0;
  const { setBottomButtons, initializeBottomButtons } = useLayout();
  const navigate = useNavigate();

  useEffect(() => {
    if (isExistCartList) {
      const goToPaymentButton: BottomButton = {
        text: '바로 주문하기',
        onClick: () => navigate(getFullPathById(ROUTE_PATHS.PAYMENT.id)),
      };

      setBottomButtons([goToPaymentButton, null]);

      return () => initializeBottomButtons();
    }
  }, []);

  return isExistCartList ? <CartContents /> : <EmptyCartContents />;
};

export default CartPage;
