import { Box, Button, Flex, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import useOrderStore from 'store/useOrderStore';
import CartItem from 'features/cart/CartItem';

const CartContents = () => {
  const { cart, selectedShop } = useOrderStore();
  const totalPrice = cart.reduce((total, order) => {
    return (total += Number(order.priceSum));
  }, 0);

  useEffect(() => {
    console.log('dddd');
  }, []);

  return (
    <Box>
      <Flex direction="column" gap="1.6rem" style={{ overflow: 'auto' }} pb="14.4rem">
        <Title order={3}>담은 레시피 - {selectedShop?.name}</Title>
        <Flex direction="column" gap="1.6rem">
          {cart.map(order => {
            return <CartItem item={order} key={order.orderId} />;
          })}
        </Flex>
        <Button fullWidth size="lg" radius="md" variant="light">
          +
        </Button>
      </Flex>
      <Flex direction="column" align="flex-end" pos="fixed" bottom="10vh" w="90vw">
        <Title order={2}>{`${totalPrice.toLocaleString('ko-KR')} 원`}</Title>
      </Flex>
    </Box>
  );
};

export default CartContents;
