import React from 'react';

export interface BottomButton {
  text: string;
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export interface LayoutContextInterface {
  firstBottomButton: BottomButton | null;
  secondBottomButton: BottomButton | null;
  initializeBottomButtons(): void;
  setBottomButtons(buttons: [BottomButton, BottomButton | null]): void;
}

const LayoutContext = React.createContext<LayoutContextInterface>({} as LayoutContextInterface);

export default LayoutContext;
