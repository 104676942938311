import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useUserStore } from '../store/useUserStore';

const API_BASE_URL = 'https://luwak.server.d0lim.com'; // 여기에 API의 기본 URL을 입력하세요.

// Axios 인스턴스 생성
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request 인터셉터
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
  const token = useUserStore.getState().accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response 인터셉터
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config;
      await useUserStore.getState().refreshAccessToken();
      const accessToken = useUserStore.getState().accessToken;
      if (accessToken) {
        originalRequest!.headers.authorization = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest!);
      } else {
        // todo : 로그아웃 페이지로 이동
        window.location.replace(`/sign-in`);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
