import { Box, Button, Flex, Pill, Text } from '@mantine/core';
import SearchBar from 'features/common/SearchBar';
import IngredientItem from './IngredientItem';
import useMenuStore from 'store/useMenuStore';

interface IngredientPopupProps {
  onClose: () => void;
}

const IngredientPopup = ({ onClose }: IngredientPopupProps) => {
  const { ingredientInfo, selectedInfo, addSelectedInfo, removeSelectedInfo } = useMenuStore();
  return (
    <Box
      p="16px"
      h="674px"
      bottom="0"
      style={{ borderRadius: '8px', borderTop: '2px solid black', background: 'white' }}
      pos="fixed"
      left="0"
      right="0">
      <Flex gap="16px" direction="column" h="100%" style={{ overflow: 'auto' }}>
        <SearchBar
          placeholder="재료 검색"
          onSearch={() => {
            console.log('search');
          }}
        />
        <Flex gap="8px">
          {['커피', '우유', '부재료', '차'].map((subCategory, i) => {
            return (
              <Button variant="filled" color="#E8E8E8" key={i}>
                <Text c="#444444">{subCategory}</Text>
              </Button>
            );
          })}
        </Flex>
        {Object.values(ingredientInfo).map((ingredientInfo, i) => {
          return (
            <IngredientItem
              key={ingredientInfo.id}
              ingredientName={ingredientInfo.name}
              ingredientImg={ingredientInfo.thumbnail}
              price={ingredientInfo.price}
              onClick={() => {
                addSelectedInfo(ingredientInfo.id);
              }}
            />
          );
        })}
        <Flex p="16px" gap="16px" direction="column">
          <Flex gap="16px">
            {Object.keys(selectedInfo).map(id => (
              <Pill
                radius="sm"
                size="md"
                color="#E8E8E8"
                key={id}
                withRemoveButton
                onRemove={() => {
                  removeSelectedInfo(id);
                }}>
                {ingredientInfo[id].name}
              </Pill>
            ))}
          </Flex>
          <Button variant="filled" color="#E8E8E8" c="black" w="100%" h="61px" onClick={onClose}>
            <Text size="16px" ta="center">
              재료 담기
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default IngredientPopup;
