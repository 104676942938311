import { Button, Flex, Loader, Text } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { postPaymentConfirm } from 'apis/payment';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getFullPathById } from 'routes';

function PaymentSuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { shopId } = useParams();
  const requestData = {
    orderId: searchParams.get('orderId') ?? '',
    amount: Number(searchParams.get('amount')) ?? 0,
    paymentKey: searchParams.get('paymentKey') ?? '',
  };
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => postPaymentConfirm(requestData),
  });

  useEffect(() => {
    const confirmPayment = async () => {
      try {
        await mutateAsync();
        navigate(getFullPathById('PAYMENT_DONE', { shopId }));
      } catch (err) {
        navigate(getFullPathById('PAYMENT_FAIL', { shopId }));
      }
    };

    confirmPayment();
  }, []);

  return (
    <Flex justify="center" align="center">
      <div>
        {isPending ? <Loader size={64} /> : null}
        <Text size="lg" fw={700}>
          결제가 진행중이에요...
        </Text>
        <div>
          <Link to="https://docs.tosspayments.com/guides/payment-widget/integration">
            <Button style={{ marginTop: '30px', marginRight: '10px' }}>연동 문서</Button>
          </Link>
          <Link to="https://discord.gg/A4fRFXQhRu">
            <Button style={{ marginTop: '30px', backgroundColor: '#e8f3ff', color: '#1b64da' }}>실시간 문의</Button>
          </Link>
        </div>
      </div>
    </Flex>
  );
}

export default PaymentSuccessPage;
