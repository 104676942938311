import { Button, Text } from '@mantine/core';
import useLayout from 'features/layout/useLayout';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';

function EmptyCartContents() {
  const navigate = useNavigate();
  const { initializeBottomButtons } = useLayout();

  useEffect(() => {
    initializeBottomButtons();
  }, []);

  return (
    <div
      style={{
        height: '80vh',
        width: '90vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Text size="lg">장바구니가 비었습니다.</Text>
      <Button mt={'10px'} onClick={() => navigate(getFullPathById(ROUTE_PATHS.ORDER.id))}>
        주문하러 가기
      </Button>
    </div>
  );
}

export default EmptyCartContents;
