import { Avatar, Box, Flex, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useOrderStore from 'store/useOrderStore';
import { ShopItem } from 'types';

export interface OrderShopItemProps {
  shop: ShopItem;
}

const OrderShopItem = ({ shop }: OrderShopItemProps) => {
  const navigate = useNavigate();
  const { setSelectedShop } = useOrderStore();
  const onClickShop = () => {
    setSelectedShop({ ...shop });
    navigate('/ingredient');
  };

  return (
    <Box p="8px" c={'black'} onClick={onClickShop} style={{ cursor: 'pointer' }}>
      <Flex justify="space-between" align="center" gap="10px">
        <Flex justify="space-between" align="center" gap="10px">
          <Avatar alt="매장 이미지" size="64px" />
          <Flex direction="column" h="41px" justify="space-between">
            <Text size="16px">{shop.name}</Text>
            <Text size="12px">{shop.description}</Text>
          </Flex>
        </Flex>
        <Text size="16px">{`${Math.round(Math.sqrt(shop.location.x ** 2 + shop.location.y ** 2))}km`}</Text>
      </Flex>
    </Box>
  );
};

export default OrderShopItem;
