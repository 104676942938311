import { Box, Button, Flex, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import MenuItem from '../component/MenuItem';
import useOrderStore from 'store/useOrderStore';
import { useGetIngredient } from 'apis/queries/shop';
import IngredientPopup from '../component/IngredientPopup';
import useMenuStore from 'store/useMenuStore';
import MenuPopup from '../component/MenuPopup';

const IngredientContents = () => {
  const { setIngredientInfo, selectedInfo, ingredientInfo } = useMenuStore();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false);
  const { addCart, selectedShop } = useOrderStore();
  const { data } = useGetIngredient(selectedShop?.id || -1);

  useEffect(() => {
    setIngredientInfo(data || []);
  }, [data]);

  return (
    <Box>
      <Box>
        <Box p="0 0 16px 0">
          <Flex direction="column" gap="16px">
            <Text size="20px">{selectedShop?.name}</Text>
            {selectedInfo.length !== 0 && (
              <Flex direction="column" gap="16px">
                {Object.entries(selectedInfo).map(([id, count]) => (
                  <MenuItem
                    key={id}
                    menuImg={ingredientInfo[id].thumbnail}
                    menuName={ingredientInfo[id].name}
                    price={ingredientInfo[id].price}
                  />
                ))}
              </Flex>
            )}
            <Button
              variant="filled"
              color="#E8E8E8"
              c="black"
              h="61px"
              onClick={() => {
                setIsPopupOpen(true);
              }}>
              <Text size="24px" fw="bold">
                +
              </Text>
            </Button>
            {selectedInfo.length === 0 && (
              <Text size="16px" ta="center" c="#444444">
                레시피에 재료를 추가해보세요!
              </Text>
            )}
          </Flex>
        </Box>
        <Box bottom="0" pos="fixed" p="16px" left="0" right="0">
          <Flex gap="16px" direction="column" bottom="0">
            <Text size="24px" ta="right">
              2,200원
            </Text>
            <Box>
              <Button
                w="100%"
                variant="filled"
                color="#E8E8E8"
                c="black"
                h="40px"
                onClick={() => {
                  setIsOrderPopupOpen(true);
                }}>
                다음
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
      {isPopupOpen && (
        <IngredientPopup
          onClose={() => {
            setIsPopupOpen(false);
          }}
        />
      )}
      {isOrderPopupOpen && (
        <MenuPopup
          onClose={() => {
            setIsOrderPopupOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default IngredientContents;
