import { IngredientItem } from 'types';
import { create } from 'zustand';

interface State {
  ingredientInfo: { [key: string]: IngredientItem };
  selectedInfo: { [key: string]: number };
}

interface Handler {
  setIngredientInfo: (ingredients: IngredientItem[]) => void;
  addSelectedInfo: (id: string) => void;
  removeSelectedInfo: (id: string) => void;
  setSelectedInfo: (id: string, count: number) => void;
}

export type StateType = State & Handler;

const initialState: State = {
  ingredientInfo: {},
  selectedInfo: {},
};

const useMenuStore = create<StateType>(set => ({
  ...initialState,
  setIngredientInfo: (ingredients: IngredientItem[]) => {
    set(state => {
      const ingredientsObj: { [key: string]: IngredientItem } = {};
      ingredients.forEach(ingredients => {
        ingredientsObj[ingredients.id] = ingredients;
      });
      state.ingredientInfo = ingredientsObj;
      return state.ingredientInfo;
    });
  },
  addSelectedInfo: (id: string) =>
    set(state => {
      const newInfo = { ...state.selectedInfo };
      if (state.ingredientInfo[id] && !state.selectedInfo[id]) {
        newInfo[id] = 1;
      }
      state.selectedInfo = newInfo;
      return state.selectedInfo;
    }),
  removeSelectedInfo: (id: string) =>
    set(state => {
      if (state.selectedInfo[id]) {
        const newInfo = { ...state.selectedInfo };
        delete newInfo[id];
        state.selectedInfo = newInfo;
      }
      return state.selectedInfo;
    }),
  setSelectedInfo: (id: string, count: number) =>
    set(state => {
      if (state.ingredientInfo[id]) {
        const newInfo = { ...state.selectedInfo };
        newInfo.id = count;
        state.selectedInfo = newInfo;
      }
      return state.selectedInfo;
    }),
}));

export default useMenuStore;
