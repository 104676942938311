import { Grid, Image, Stack, Text } from '@mantine/core';
import { formatPrice, sum } from './utils';
import { CartOrderItem } from 'types';

interface Props {
  order: CartOrderItem;
}

function ToPayOrderItem({ order }: Props) {
  return (
    <Grid key={order.orderId} align="center">
      <Grid.Col span="content">
        <Image radius={9999} w={64} h={64} alt="레시피 이미지" src={'/public/assets/images/coffee_sample.jpeg'} />
      </Grid.Col>
      <Grid.Col span="auto">
        <Stack gap={8}>
          <Text size="sm" lineClamp={1}>
            {formatOrderInfo(order)}
          </Text>
          <Text size="sm">{formatPrice(Number(order.priceSum))}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span="content">{order.quantity}</Grid.Col>
    </Grid>
  );
}

const formatOrderInfo = (order: CartOrderItem) => {
  const iced = order.temperatureType;
  const size = order.sizeType;
  const ingredients = order.ingredients.map(ingredient => `${ingredient.ingredient.name} ${ingredient.quantity}`);

  return [iced, size, ...ingredients].join(' | ');
};

const getOrderPrice = (order: CartOrderItem) => {
  const price = sum(order.ingredients.map(ingredient => ingredient.ingredient.price * ingredient.quantity));

  return price;
};

export default ToPayOrderItem;
