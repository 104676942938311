import { Avatar, Box, Flex, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import React, { MouseEventHandler } from 'react';

export interface IngredientItemProps {
  ingredientName: string;
  ingredientImg: string;
  price: number;
  onClick?: MouseEventHandler;
}

const IngredientItem = ({ ingredientName, ingredientImg, price, onClick }: IngredientItemProps) => {
  const { hovered, ref } = useHover();
  return (
    <Box p="8px" c="black" ref={ref} bg={hovered ? '#E8E8E8' : ''} style={{ borderRadius: '8px' }} onClick={onClick}>
      <Flex justify="space-between" align="center" gap="10px">
        <Flex justify="space-between" align="center" gap="10px">
          <Avatar alt="재료 이미지" src={ingredientImg} size="64px" />
          <Text size="16px">{ingredientName}</Text>
        </Flex>
        <Text size="16px">{`${price.toLocaleString('ko-KR')} 원`}</Text>
      </Flex>
    </Box>
  );
};

export default IngredientItem;
