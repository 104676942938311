import { ActionIcon, Avatar, Box, Flex, Image, Text } from '@mantine/core';
import React, { useState } from 'react';

export interface MenuItemProps {
  menuName: string;
  menuImg: string;
  price: number;
}

const MenuItem = ({ menuName, menuImg, price }: MenuItemProps) => {
  const [count, setCount] = useState(1);
  return (
    <Box p="8px">
      <Flex justify="space-between" align="center" gap="10px">
        <Flex justify="space-between" align="center" gap="10px">
          <Avatar alt="메뉴 이미지" src={menuImg} size="64px" />
          <Flex direction="column" h="41px" justify="space-between">
            <Text size="16px">{menuName}</Text>
            <Text size="12px">{`${price.toLocaleString('ko-KR')} 원`}</Text>
          </Flex>
        </Flex>
        <Flex w="72px" justify="space-between" c="#242424">
          <ActionIcon
            variant="default"
            radius="xl"
            size="24px"
            onClick={() => {
              setCount(Math.max(count - 1, 0));
            }}>
            <Image src="assets/svg/icoMinus.svg" w="12px" />
          </ActionIcon>
          <Text size="16px" style={{ lineHeight: '24px' }}>
            {count}
          </Text>
          <ActionIcon
            variant="default"
            radius="xl"
            size="24px"
            onClick={() => {
              setCount(count + 1);
            }}>
            <Image src="assets/svg/icoPlus.svg" w="12px" />
          </ActionIcon>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MenuItem;
