import React, { Suspense, useEffect } from 'react';
import HomeContents from 'features/home/HomeContents';
import useLayout from 'features/layout/useLayout';
import { BottomButton } from 'features/layout/LayoutContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import { HomeSkeleton } from 'features/home/HomeSkeleton';

function HomePage(): JSX.Element {
  const { setBottomButtons, initializeBottomButtons } = useLayout();
  const navigate = useNavigate();

  useEffect(() => {
    const orderButton: BottomButton = {
      text: '나만의 커피 주문하기',
      onClick: () => navigate(getFullPathById(ROUTE_PATHS.ORDER.id)),
    };

    setBottomButtons([orderButton, null]);

    return () => initializeBottomButtons();
  }, []);
  
  return (
    <Suspense fallback={<HomeSkeleton />}>
      <HomeContents />
    </Suspense>
  );
}

export default HomePage;
