import {
  Input,
  InputWrapper,
  PasswordInput,
  Title,
  Space,
  Fieldset,
  Group,
  Button,
  Flex,
  CloseButton,
  Modal,
  Text,
} from '@mantine/core';
import { useForm, isEmail, isNotEmpty, matches } from '@mantine/form';
import { useDisclosure, useDebouncedCallback } from '@mantine/hooks';
import { checkEmail, checkNickname, signUp } from 'apis/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import { useUserStore } from 'store/useUserStore';

interface SignUpInput {
  nickname: string;
  email: string;
  password: string;
  passwordCheck: string;
}

const initialSignUpInput: SignUpInput = {
  nickname: '',
  email: '',
  password: '',
  passwordCheck: '',
} as const;

const passwordRegexp = /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[{}[\]/?.,;:|)*~`!^-_+<>@#$%&\\=('"]).*$/;

function SignUpForm(): JSX.Element {
  const form = useForm<SignUpInput>({
    validateInputOnChange: true,
    initialValues: initialSignUpInput,
    validate: {
      nickname: isNotEmpty('닉네임을 입력해주세요.'),
      email: isEmail('이메일 형식에 맞게 입력해주세요.'),
      password: matches(passwordRegexp, '영문, 숫자, 특수문자를 혼합해 8~20자로 입력해주세요.'),
      passwordCheck: (value, values) => (value !== values.password ? '비밀번호가 일치하지 않습니다.' : null),
    },
  });

  form.watch('password', () => {
    form.validateField('passwordCheck');
  });

  form.watch('nickname', ({ previousValue, value }) => {
    doCheckNickname(value);
  });

  form.watch('email', ({ previousValue, value }) => {
    doCheckEmail(value);
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure();
  const navigate = useNavigate();

  const setAccessToken = useUserStore(state => state.setAccessToken);
  const setRefreshToken = useUserStore(state => state.setRefreshToken);

  const doSignUp = async (signUpInput: SignUpInput) => {
    setLoading(true);
    try {
      const response = await signUp({
        nickname: signUpInput.nickname,
        email: signUpInput.email,
        password: signUpInput.password,
      });
      setAccessToken(response.data.accessToken);
      setRefreshToken(response.data.refreshToken);
      open();
    } catch (e) {
      form.setErrors({ failToSignUp: '회원가입에 실패했습니다.\n잠시 후 다시 시도해주세요.' });
    } finally {
      setLoading(false);
    }
  };

  const doCheckNickname = useDebouncedCallback(async (nickname: string) => {
    const response = await checkNickname({ nickname });
    if (response && response.data.exists) {
      form.setErrors({ nickname: '이미 사용 중인 닉네임입니다.' });
    }
  }, 1000);

  const doCheckEmail = useDebouncedCallback(async (email: string) => {
    const response = await checkEmail({ email });
    if (response && response.data.exists) {
      form.setErrors({ email: '이미 사용 중인 이메일입니다.' });
    }
  }, 1000);

  return (
    <>
      <Flex h="90vh" mx="20px" my="40px" direction="column" justify="center" align="center">
        <Title order={3}>Tailored Coffee와 함께</Title>
        <Title order={3}>나만의 커피를 즐겨볼까요?</Title>

        <Space h="xl" />

        <Fieldset legend="회원가입">
          <form onSubmit={form.onSubmit(values => doSignUp(values))}>
            <InputWrapper
              label="닉네임"
              withAsterisk
              description="Tailored Coffee에서 사용할 닉네임을 입력해주세요"
              error={form.errors.nickname}>
              <Input
                {...form.getInputProps('nickname')}
                rightSectionPointerEvents="all"
                rightSection={
                  <CloseButton
                    aria-label="Clear input"
                    onClick={() => form.setFieldValue('nickname', '')}
                    style={{ display: form.values.nickname ? undefined : 'none' }}
                  />
                }
              />
            </InputWrapper>

            <InputWrapper
              label="이메일"
              withAsterisk
              description="주문내역 발송, 비밀번호 초기화 등에 사용될 수 있어요"
              mt="md"
              error={form.errors.email}>
              <Input
                {...form.getInputProps('email')}
                rightSectionPointerEvents="all"
                rightSection={
                  <CloseButton
                    aria-label="Clear input"
                    onClick={() => form.setFieldValue('email', '')}
                    style={{ display: form.values.email ? undefined : 'none' }}
                  />
                }
              />
            </InputWrapper>

            <PasswordInput
              {...form.getInputProps('password')}
              label="비밀번호"
              withAsterisk
              description="영문, 숫자, 특수문자를 혼합해 8~20자로 입력해주세요"
              mt="md"
            />

            <PasswordInput
              {...form.getInputProps('passwordCheck')}
              label="비밀번호 확인"
              withAsterisk
              description="위에 입력한 비밀번호를 한 번 더 입력해주세요"
              mt="md"
            />

            <Group justify="center" mt="md">
              <Text size="sm" c="red">
                {form.errors.failToSignUp}
              </Text>
            </Group>

            <Group justify="flex-end" mt="xl">
              <Button type="submit" loading={loading} disabled={!form.isValid()}>
                가입하기
              </Button>
            </Group>
          </form>
        </Fieldset>
      </Flex>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        transitionProps={{ transition: 'fade', duration: 200 }}>
        <Flex justify="center" align="center" direction="column" gap="md">
          <Title order={3}>회원가입 완료</Title>
          <Title order={5}>Tailored Coffee의 회원이 되신 걸 축하합니다!</Title>
          <Flex direction="row" gap="md">
            <Button onClick={() => navigate(getFullPathById(ROUTE_PATHS.HOME.id))}>홈으로 이동</Button>
            <Button onClick={() => navigate(getFullPathById(ROUTE_PATHS.ORDER.id))}>주문하러 가기</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default SignUpForm;
