import {
  Flex,
  Title,
  CloseButton,
  PasswordInput,
  Fieldset,
  Space,
  Group,
  Button,
  Anchor,
  TextInput,
  Text,
} from '@mantine/core';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import { useForm, isEmail } from '@mantine/form';
import { signIn } from 'apis/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'store/useUserStore';

interface SignInInput {
  email: string;
  password: string;
}

const initialSignInInput: SignInInput = {
  email: '',
  password: '',
} as const;

function SignInForm(): JSX.Element {
  const form = useForm<SignInInput>({
    validateInputOnChange: true,
    initialValues: initialSignInInput,
    validate: {
      email: isEmail('이메일 형식에 맞게 입력해주세요.'),
    },
  });
  const signUpLink = getFullPathById(ROUTE_PATHS.SIGNUP.id);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const setAccessToken = useUserStore(state => state.setAccessToken);
  const setRefreshToken = useUserStore(state => state.setRefreshToken);

  async function doLogin(signInInput: SignInInput) {
    setLoading(true);
    form.resetDirty();

    try {
      const response = await signIn({ email: signInInput.email, password: signInInput.password });
      if (response) {
        form.initialize(initialSignInInput);
        setAccessToken(response.data.accessToken);
        setRefreshToken(response.data.refreshToken);
        navigate(getFullPathById(ROUTE_PATHS.HOME.id));
      }
    } catch (e) {
      form.setErrors({ failToLogin: '이메일 또는 비밀번호를 확인해주세요.' });
    } finally {
      setLoading(false);
    }
  }

  const resetFailToLogin = () => {
    if (form.errors.failToLogin) {
      form.setErrors({ failToLogin: null });
    }
  };

  return (
    <Flex h="95vh" direction="column" justify="center" align="center">
      <Title order={3}>어서오세요,</Title>
      <Title order={3}> Tailored Coffee입니다! </Title>

      <Space h="xl" />

      <Fieldset legend="로그인" w="80vw">
        <form onSubmit={form.onSubmit(values => doLogin(values))}>
          <TextInput
            label="이메일"
            placeholder="이메일을 입력해주세요"
            {...form.getInputProps('email')}
            error={form.errors.email}
            onInput={() => resetFailToLogin()}
            rightSectionPointerEvents="all"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => form.setFieldValue('email', '')}
                style={{ display: form.values.email ? undefined : 'none' }}
              />
            }
          />

          <PasswordInput
            {...form.getInputProps('password')}
            onInput={() => resetFailToLogin()}
            label="비밀번호"
            placeholder="비밀번호를 정확히 입력해주세요"
            mt="md"
          />

          <Group justify="center" mt="md">
            <Text size="sm" c="red">
              {form.errors.failToLogin}
            </Text>
          </Group>

          <Group justify="space-between" mt="md">
            <Anchor fz="sm" underline="always" href={signUpLink}>
              회원가입이 필요하신가요?
            </Anchor>
            <Button
              type="submit"
              loading={loading}
              disabled={!form.isValid() || (!form.isDirty('email') && !form.isDirty('password'))}>
              로그인
            </Button>
          </Group>
        </form>
      </Fieldset>
    </Flex>
  );
}

export default SignInForm;
