import { SimpleGrid } from '@mantine/core';
import { PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk';
import { useEffect, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';

interface Props {
  price: number;
}

function PaymentWidget({ price }: Props) {
  const selector = '#payment-widget';
  const { paymentWidget } = useLoaderData() as { paymentWidget: PaymentWidgetInstance | undefined };
  const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance['renderPaymentMethods']> | null>(null);

  useEffect(() => {
    if (!paymentWidget) {
      return;
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      selector,
      { value: price },
      { variantKey: 'DEFAULT' },
    );
    paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(price);
  }, []);

  return (
    <>
      <div id="payment-widget" />
      <div id="agreement" />
    </>
  );
}

export default PaymentWidget;
