import axiosInstance from 'apis';

interface PostPaymentConfirmRequestBody {
  paymentKey: string;
  orderId: string;
  amount: number;
}

export const postPaymentConfirm = async (body: PostPaymentConfirmRequestBody) => {
  try {
    const res = await axiosInstance.post('/payments/confirm', body);

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
