import { SimpleGrid, Skeleton, Flex } from '@mantine/core';

export function HomeSkeleton() {
  return (
    <SimpleGrid>
      <div style={{ gap: '7px', display: 'flex', flexDirection: 'column' }}>
        <Skeleton height={35} width="70%" />
        <Skeleton height={27} width="90%" />
      </div>
      <SimpleGrid verticalSpacing="xs">
        <Skeleton height={30} width="30%" />
        <div>
          <Skeleton height={27} mt="5px" width="26%" radius="md" />
        </div>
        <Flex direction="row" align="center" gap={16}>
          <Skeleton height={'4.5rem'} circle />
          <Skeleton height={'4.3rem'} radius="lg" width="70%" />
        </Flex>
        <Flex direction="row" align="center" gap={16}>
          <Skeleton height={'4.5rem'} circle />
          <Skeleton height={'4.3rem'} radius="lg" width="70%" />
        </Flex>
        <Flex direction="row" align="center" gap={16}>
          <Skeleton height={'4.5rem'} circle />
          <Skeleton height={'4.3rem'} radius="lg" width="70%" />
        </Flex>
        <Flex direction="row" align="center" gap={16}>
          <Skeleton height={'4.5rem'} circle />
          <Skeleton height={'4.3rem'} radius="lg" width="70%" />
        </Flex>
      </SimpleGrid>
    </SimpleGrid>
  );
}
