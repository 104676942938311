import React from 'react';
import { Link, LinkProps, Params, RouteObject, useParams } from 'react-router-dom';
import HomePage from 'pages/home';
import ErrorPage from 'pages/error';
import Layout from 'features/layout/Layout';
import OrderPage from 'pages/order';
import SignUp from 'pages/signUp';
import SignIn from 'pages/singIn';
import PaymentPage, { loader as paymentPageLoader } from 'pages/payment';
import PaymentSuccessPage from 'pages/payment/success';
import PaymentFailPage from 'pages/payment/fail';
import IngredientPage from 'pages/order/Ingredient';
import CartPage from 'pages/cart';
import PaymentDonePage from 'pages/payment/done';

interface RoutePathItem {
  id: string;
  getFullPath: (params?: Params) => string;
}

interface RoutePath {
  [key: string]: RoutePathItem;
}

export const ROUTE_PATHS: RoutePath = {
  HOME: {
    id: 'HOME',
    getFullPath: () => '/',
  },
  PAGE_NOT_FOUND: {
    id: 'PAGE_NOT_FOUND',
    getFullPath: () => '/error',
  },
  ORDER: {
    id: 'ORDER',
    getFullPath: () => '/order',
  },
  SIGNUP: {
    id: 'SIGNUP',
    getFullPath: () => '/sign-up',
  },
  SIGNIN: {
    id: 'SIGNIN',
    getFullPath: () => '/sign-in',
  },
  PAYMENT: {
    id: 'PAYMENT',
    getFullPath: () => '/:shopId/payment',
  },
  PAYMENT_SUCCESS: {
    id: 'PAYMENT_SUCCESS',
    getFullPath: () => '/:shopId/payment/success',
  },
  PAYMENT_FAIL: {
    id: 'PAYMENT_FAIL',
    getFullPath: () => '/:shopId/payment/fail',
  },
  PAYMENT_DONE: {
    id: 'PAYMENT_DONE',
    getFullPath: () => '/:shopId/payment/done',
  },
  INGREDIENT: {
    id: 'INGREDIENT',
    getFullPath: () => '/ingredient',
  },
  CART: {
    id: 'CART',
    getFullPath: () => '/cart',
  },
} as const;

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: '/:shopId/payment',
        element: <PaymentPage />,
        loader: paymentPageLoader,
      },
      {
        path: '/:shopId/payment/success',
        element: <PaymentSuccessPage />,
      },
      {
        path: '/:shopId/payment/fail',
        element: <PaymentFailPage />,
      },
      {
        path: '/:shopId/payment/done',
        element: <PaymentDonePage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '/order',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <OrderPage />,
      },
    ],
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/ingredient',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <IngredientPage />,
      },
    ],
  },
  {
    path: '/cart',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <CartPage />,
      },
    ],
  },
];

export function getFullPathById(id: string, params?: Params) {
  const pathToGo = Object.values(ROUTE_PATHS).find(path => path.id === id);
  return pathToGo ? pathToGo.getFullPath(params) : ROUTE_PATHS.PAGE_NOT_FOUND.getFullPath();
}

interface LinkByIdProps extends Omit<LinkProps, 'to'> {
  id: string;
  search?: string;
  hash?: string;
  params?: Params;
}

export function LinkById({ id, search = undefined, hash = undefined, params, ...rest }: LinkByIdProps) {
  const routeParams = useParams();
  const pathname = getFullPathById(id, { ...routeParams, ...params });

  return <Link to={{ pathname, search, hash }} {...rest} />;
}
