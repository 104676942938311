import { ActionIcon, TextInput, Image } from '@mantine/core';
import React, { useRef } from 'react';

export interface SearchBarProps {
  placeholder: string;
  onSearch: (searchWord: string) => void;
}

const SearchBar = ({ placeholder, onSearch }: SearchBarProps) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  return (
    <TextInput
      placeholder={placeholder}
      w="100%"
      h="40px"
      size="md"
      radius="8px"
      ref={searchBarRef}
      rightSection={
        <ActionIcon
          variant="transparent"
          color="#242424"
          onClick={() => {
            onSearch(searchBarRef.current?.value || '');
          }}>
          <Image src="assets/svg/IconSearch.svg" />
        </ActionIcon>
      }
    />
  );
};

export default SearchBar;
