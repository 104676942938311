import { Button, Grid } from '@mantine/core';
import { Place } from './types';

interface Props {
  place: Place;
  onChangePlace: (place: Place) => void;
}

function OrderPlace({ place, onChangePlace }: Props) {
  const handleChangePlace = (place: Place) => {
    onChangePlace(place);
  };

  return (
    <Grid justify="space-between" align="center" classNames={{ root: 'py-[16px]' }}>
      <Grid.Col span={6}>
        <Button
          fullWidth
          size="lg"
          radius="md"
          color={place !== 'TO_GO' ? '#e8e8e8' : 'blue'}
          onClick={() => handleChangePlace('TO_GO')}>
          To Go
        </Button>
      </Grid.Col>
      <Grid.Col span={6}>
        <Button
          fullWidth
          size="lg"
          radius="md"
          color={place !== 'FOR_HERE' ? '#e8e8e8' : 'blue'}
          onClick={() => handleChangePlace('FOR_HERE')}>
          매장에서
        </Button>
      </Grid.Col>
    </Grid>
  );
}

export default OrderPlace;
