import { Box, Button, Flex, Text } from '@mantine/core';

interface OrderPopupProps {
  onClose: () => void;
}

const MenuPopup = ({ onClose }: OrderPopupProps) => {
  return (
    <Box
      p="16px"
      h="460px"
      bottom="0"
      style={{ borderRadius: '8px', borderTop: '2px solid black', background: 'white' }}
      pos="fixed"
      left="0"
      right="0">
      <Flex gap="16px" direction="column" h="100%" style={{ overflow: 'auto' }}>
        <Text size="24px">음료 제작 옵션</Text>
        <Text size="24px">Hot/Iced</Text>
        <Flex gap="16px">
          {['Hot', 'Iced'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
        <Text size="24px">Size</Text>
        <Flex gap="16px">
          {['Tall', 'Grande', 'Venti'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
        <Text size="24px">Water(100%는 사이즈에 맞게 채워드립니다)</Text>
        <Flex gap="16px">
          {['No', '적게', '가득'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
        <Text size="24px">Ice</Text>
        <Flex gap="16px">
          {['No', '적게', '보통'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
        <Flex pt="16px" gap="16px" w="100%">
          <Button
            variant="filled"
            color="#E8E8E8"
            w="100%"
            h="51px"
            onClick={() => {
              onClose();
            }}>
            <Text size="16px" c="black">
              장바구니 담기
            </Text>
          </Button>
          <Button variant="filled" color="#E8E8E8" w="100%" h="51px">
            <Text size="16px" c="black">
              바로 주문하기
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MenuPopup;
