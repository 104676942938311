import React, { useEffect, useState } from 'react';
import { Flex, Text, Box, Avatar, ActionIcon } from '@mantine/core';
import { CartOrderItem } from 'types';
import coffeeImg from 'assets/img/coffee_icon.png';
import useOrderStore from 'store/useOrderStore';

export interface CartItemProps {
  item: CartOrderItem;
}

const CartItem = ({ item }: CartItemProps) => {
  const [count, setCount] = useState(item.quantity);
  const { modifyCart, removeCart } = useOrderStore();
  const formatIngredients = () => {
    return item.ingredients.map((orderedIngredient, index) => {
      const isLast = index === item.ingredients.length - 1;
      return (
        <Text key={orderedIngredient.ingredient.name}>
          {`${orderedIngredient.ingredient.name} ${orderedIngredient.quantity}${!isLast ? ' |' : ''}`}&nbsp;
        </Text>
      );
    });
  };

  const getPrice = () => {
    return item.ingredients.reduce((total, orderedIngredient) => {
      return (total += orderedIngredient.ingredient.price * orderedIngredient.quantity);
    }, 0);
  };

  useEffect(() => {
    modifyCart({ ...item, quantity: count });
  }, [count]);

  return (
    <Box>
      <Flex justify="space-between" align="center" gap="sm">
        <Flex justify="space-between" align="center" gap="sm">
          <Avatar size="5rem" src={coffeeImg} />
          <Flex direction="column" justify="space-between" gap="sm">
            <Flex wrap="wrap">
              <Text>{item.temperatureType} |&nbsp;</Text>
              <Text>{item.sizeType} |&nbsp;</Text>
              {formatIngredients()}
            </Flex>
            <Text size="1.2rem">{`${getPrice().toLocaleString('ko-KR')} 원`}</Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" gap="xs">
          {count !== 0 ? (
            <ActionIcon
              variant="default"
              radius="xl"
              size="sm"
              onClick={() => {
                setCount(Math.max(count - 1, 0));
              }}>
              <Text size="md">-</Text>
            </ActionIcon>
          ) : (
            <ActionIcon
              variant="default"
              radius="xl"
              size="sm"
              color="red"
              onClick={() => {
                removeCart(item.orderId);
              }}>
              <Text size="md" color="red">
                x
              </Text>
            </ActionIcon>
          )}
          <Text size="sm">{count}</Text>
          <ActionIcon
            variant="default"
            radius="xl"
            size="sm"
            onClick={() => {
              setCount(count + 1);
            }}>
            <Text size="md">+</Text>
          </ActionIcon>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CartItem;
