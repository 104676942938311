import { Stack } from '@mantine/core';
import React from 'react';
import LastOrderItem from './LastOrderItem';
import { getRecentOrderList } from 'apis/order';
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useIntersectionObserver } from 'shared/useIntersectionObserver';
import { getShopList } from 'apis/shop';

function LastOrderList(): JSX.Element {
  const {
    data: lastOrderData,
    fetchNextPage,
    hasNextPage,
  } = useSuspenseInfiniteQuery({
    queryKey: [getRecentOrderList.name],
    queryFn: ({ pageParam = 1 }) => getRecentOrderList({ page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => (lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined),
    select: data => ({
      pages: data?.pages.flatMap(page => page.data),
      pageParams: data.pageParams,
    }),
  });

  const { data: shopListData } = useSuspenseQuery({
    queryKey: [getShopList.name],
    queryFn: () => getShopList(),
  });

  const { setTarget } = useIntersectionObserver({ threshold: 1, hasNextPage, fetchNextPage });

  return lastOrderData?.pages.length !== 0 ? (
    <Stack>
      {lastOrderData?.pages.map(lastOrder => (
        <LastOrderItem
          key={lastOrder.orderId}
          item={{ ...lastOrder, shop: shopListData?.data.find(shopData => shopData.id === lastOrder.shopId) }}
        />
      ))}
      <div ref={setTarget}></div>
    </Stack>
  ) : (
    <Stack>
      <div>최근 주문한 목록이 없습니다.</div>
    </Stack>
  );
}

export default LastOrderList;
