import { Button, Text, Title } from '@mantine/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getFullPathById } from 'routes';

function PaymentFailPage() {
  const [searchParams] = useSearchParams();
  const { shopId } = useParams();

  const errorCode = searchParams.get('code');
  const errorMessage = searchParams.get('message');

  return (
    <div className="flex justify-center items-center">
      <div>
        <Title order={2}>
          <img width="30px" src="https://static.toss.im/3d-emojis/u1F6A8-apng.png" />
          결제 실패
        </Title>
        <Text>{`code = ${errorCode}`}</Text>
        <Text>{`message = ${errorMessage}`}</Text>
        <div>
          <Link to={getFullPathById('PAYMENT', { shopId })}>결제 페이지로 돌아가기</Link>
        </div>
        <div>
          <Link to="https://docs.tosspayments.com/guides/payment-widget/integration">연동 문서</Link>
          <Link to="https://discord.gg/A4fRFXQhRu">실시간 문의</Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailPage;
