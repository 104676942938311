import axiosInstance from 'apis';
import {
  SignUpParams,
  SignInParams,
  CheckNicknameParams,
  CheckEmailParams,
  RefreshAccessTokenParams,
  UserData,
} from 'apis/types';

export function signUp({ nickname, email, password }: SignUpParams) {
  return axiosInstance.post('/auth/signup', { nickname, email, password });
}

export function signIn({ email, password }: SignInParams) {
  return axiosInstance.post('/auth/login', { email, password });
}

export function checkNickname({ nickname }: CheckNicknameParams) {
  return axiosInstance.post('/auth/check-nickname-exists', { nickname });
}

export function checkEmail({ email }: CheckEmailParams) {
  return axiosInstance.post('/auth/check-email-exists', { email });
}

export function getUser() {
  return axiosInstance.get('/auth/me');
}

export function refreshAccessToken({ oldRefreshToken }: RefreshAccessTokenParams) {
  return axiosInstance.post('/auth/refresh', { oldRefreshToken });
}
