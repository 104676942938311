import { Grid, SimpleGrid, Text, Title } from '@mantine/core';
import ToPayOrderList from './ToPayOrderList';
import OrderPlace from './OrderPlace';
import PaymentWidget from './PaymentWidget';
import { getTotalPrice } from './utils';
import { Place } from './types';
import useOrderStore from 'store/useOrderStore';

interface Props {
  place: Place;
  onChangePlace: (place: Place) => void;
}

function PaymentContents({ place, onChangePlace }: Props) {
  const { cart } = useOrderStore();

  return (
    <SimpleGrid cols={1} spacing={0}>
      <Grid justify="space-between" align="center" classNames={{ root: 'pb-[16px]' }}>
        <Grid.Col span="content">
          <Title order={3}>주문 매장</Title>
        </Grid.Col>
        <Grid.Col span="content">
          <Text size="md">판교역점</Text>
        </Grid.Col>
      </Grid>
      <OrderPlace place={place} onChangePlace={onChangePlace} />
      <SimpleGrid cols={1} classNames={{ root: 'py-[16px]' }}>
        <header>
          <Title order={3}>주문 레시피</Title>
        </header>
        <ToPayOrderList orderList={cart} />
      </SimpleGrid>
      <SimpleGrid>
        <PaymentWidget price={getTotalPrice(cart)} />
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default PaymentContents;
