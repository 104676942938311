import { CartOrderItem } from 'types';

export const sum = (values: number[]) => values.reduce((total, value) => total + value, 0);

export const formatPrice = (price: number) => {
  return `${price.toLocaleString()}원`;
};

export const capitalizeFirstLetter = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
};

export const getTotalPrice = (cart: CartOrderItem[]) => {
  const totalPrice = sum(cart.map(cartItem => Number(cartItem.priceSum) * cartItem.quantity));

  return totalPrice;
};
