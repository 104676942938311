import axios from 'axios';
import { create } from 'zustand';

interface User {
  id: number;
  nickname: string;
  email: string;
}

interface UserStore {
  user: User | null;
  accessToken: string | null;
  refreshToken: string | null;
  setUser: (user: User) => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  clearUser: () => void;
  refreshAccessToken: () => Promise<void>;
}

const ACCESS_TOKEN_KEY = 'luwak-access-token';
const REFRESH_TOKEN_KEY = 'luwak-refresh-token';

export const useUserStore = create<UserStore>(set => ({
  user: null,
  accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  setUser: (user: User) => set({ user }),
  setAccessToken: (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    set({ accessToken: token });
  },
  setRefreshToken: (token: string) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
    set({ refreshToken: token });
  },
  clearUser: () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    set({ user: null, accessToken: null, refreshToken: null });
  },
  refreshAccessToken: async () => {
    try {
      const { refreshToken } = useUserStore.getState();
      if (refreshToken) {
        const response = await axios.post('/auth/refresh', { oldRefreshToken: refreshToken });
        const { accessToken } = response.data;
        useUserStore.getState().setAccessToken(accessToken);
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      useUserStore.getState().clearUser();
    }
  },
}));
