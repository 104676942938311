import axiosInstance from 'apis';
import { ApiListResponse } from './types';
import { IngredientItem, ShopItem } from 'types';

export function getShopList(): Promise<ApiListResponse<ShopItem>> {
  return axiosInstance.get('/shop/list');
}

export const getIngredientList = async (shopId: number) => {
  try {
    const res = await axiosInstance.get<IngredientItem[]>(`/shop/${shopId}/ingredients`);
    // return res.data;
    return [
      {
        id: '0',
        name: '콜드브루',
        price: 1000,
        description: '마싰는 커피^^',
        thumbnail: 'https://image.istarbucks.co.kr/upload/store/skuimg/2021/04/[9200000002081]_20210415133656839.jpg',
      },
      {
        id: '1',
        name: '우유',
        price: 2100,
        description: '흰 우유 입니다.',
        thumbnail: 'https://image.istarbucks.co.kr/upload/store/skuimg/2021/04/[9200000002081]_20210415133656839.jpg',
      },
      {
        id: '2',
        name: '마법가루',
        price: 700,
        description: '우리 커피점 비법 재료',
        thumbnail: 'https://image.istarbucks.co.kr/upload/store/skuimg/2021/04/[9200000002081]_20210415133656839.jpg',
      },
    ];
  } catch (err) {
    console.error(err);
    throw err;
  }
};
