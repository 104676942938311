import { SimpleGrid } from '@mantine/core';
import OrderItem from './ToPayOrderItem';
import { CartOrderItem } from 'types';

interface Props {
  orderList: CartOrderItem[];
}

function ToPayOrderList({ orderList }: Props) {
  return (
    <SimpleGrid cols={1}>
      {orderList.map(order => (
        <OrderItem key={order.orderId} order={order} />
      ))}
    </SimpleGrid>
  );
}

export default ToPayOrderList;
