import React, { Suspense } from 'react';
import LastOrderList from 'features/home/LastOrderList';
import { Button, SimpleGrid, Skeleton, Text, Title } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getUser } from 'apis/user';

function HomeContents(): JSX.Element {
  const { data: userData } = useSuspenseQuery({
    queryKey: [getUser.name],
    queryFn: () => getUser(),
  });

  return (
    <SimpleGrid>
      <div>
        <Title order={3}>{userData?.data.nickname}님, 안녕하세요!</Title>
        <Text size="lg">따뜻한 커피와 함께 오후를 시작헤보세요</Text>
      </div>
      <SimpleGrid verticalSpacing="xs">
        <Text size="xl" fw={500}>
          Quick Order
        </Text>
        <div>
          <Button size="xs" radius="md">
            최근 레시피
          </Button>
        </div>
        <LastOrderList />
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default HomeContents;
