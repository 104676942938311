import React from 'react';
import { Text, Stack, Button } from '@mantine/core';

function ErrorPage(): JSX.Element {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Text size="lg">예상치 못한 에러가 발생했습니다.</Text>
      <Text size="lg">잠시 후 다시 시도해주세요.</Text>
      <Button mt={'10px'} onClick={() => window.location.reload()}>
        다시 시도
      </Button>
    </div>
  );
}

export default ErrorPage;
